import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Container, Grid, TextField, Paper, Button, Box, Typography, InputLabel, Select, MenuItem } from '@mui/material';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';
import Header from '../../../components/header';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import sectionsAdmin from '../../../utils/menu/admin';
import { AlertSettings, AlertSettingsProps } from '../../../utils/interfaces/alert';
import ImageConfigurationService from '../../../services/admin/imageConfiguration';
import defaultTheme from '../../../utils/style';

interface ImageConfiguration {
    id: number;
    pageNumber: number;
    opacity: number;
    size: number;
    amount: number;
    pageResize: number;
}

const ImageConfigurationManager: React.FC = () => {
    const [configuration, setConfiguration] = useState<ImageConfiguration>({
        id: 1,
        pageNumber: 0,
        opacity: 0,
        size: 0,
        amount: 0,
        pageResize: 0
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success',
    });

    useEffect(() => {
        const fetchConfiguration = async () => {
            setLoading(true);
            try {
                const response = await ImageConfigurationService.get();
                setConfiguration(response.data);
            } catch (error) {
                setAlertSettings(AlertSettings.fromError(error));
                setOpenAlert(true);
            } finally {
                setLoading(false);
            }
        };
        fetchConfiguration();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target;
        if (name) {
            setConfiguration((prevConfig) => ({
                ...prevConfig,
                [name]: value,
            }));
        }
    };

    const handleSend = async () => {
        setLoading(true);
        try {
            await ImageConfigurationService.post(configuration);
            setAlertSettings({ message: 'Configuration Saved.', type: 'success' });
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
        } finally {
            setOpenAlert(true);
            setLoading(false);
        }
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert}
            />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Storie Manager" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                            <Box sx={{ mt: 4 }}>
                                <Typography variant="h6" gutterBottom>
                                    Image Configuration
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Page Number"
                                    variant="outlined"
                                    name="pageNumber"
                                    value={configuration.pageNumber}
                                    onChange={handleChange}
                                    type="number"
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Opacity"
                                    variant="outlined"
                                    name="opacity"
                                    value={configuration.opacity}
                                    onChange={handleChange}
                                    type="number"
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Size"
                                    variant="outlined"
                                    name="size"
                                    value={configuration.size}
                                    onChange={handleChange}
                                    type="number"
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Amount"
                                    variant="outlined"
                                    name="amount"
                                    value={configuration.amount}
                                    onChange={handleChange}
                                    type="number"
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Page Resize"
                                    variant="outlined"
                                    name="pageResize"
                                    value={configuration.pageResize}
                                    onChange={handleChange}
                                    type="number"
                                    sx={{ mb: 2 }}
                                />
                                <Button variant="contained" color="primary" onClick={handleSend}>
                                    Save Configuration
                                </Button>
                            </Box>
                        </Paper>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default ImageConfigurationManager;
