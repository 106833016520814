import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Container, CssBaseline, Grid } from '@mui/material';
import ActionAreaCard from '../../../components/card/index';
import Header from '../../../components/header';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import StorieService from '../../../services/storie';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';
import BaseUrl from '../../../utils/Api';
import sections from '../../../utils/menu';
import { formatDateToMMDDYYYY } from '../../../utils/format/date';
import TokenService from '../../../services/token'; // Importe seu serviço aqui
import { AlertSettings, AlertSettingsProps } from '../../../utils/interfaces/alert';
import { saveToSessionStorage } from '../../../utils/session';
import { useNavigate } from 'react-router-dom';
import defaultTheme from '../../../utils/style';

interface FeaturePost {
    storieId: number;
    name: string;
    cover: string;
    lastUpdate: string;
    views: number;
    tierTitle: string;
}

const StorieNoTextCards: React.FC = () => {
    const navigate = useNavigate();
    const [featuredPosts, setFeaturedPost] = useState<FeaturePost[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });

    const check = async () => {
        try {
            const response = await TokenService.get();
            const invalid = Boolean(response.data);

            if (invalid) {
                navigate("/Subscriber");
                return false;
            }
        } catch (error) {
            const alert = AlertSettings.fromError(error);
            setAlertSettings(alert);
            setOpenAlert(true);
            return false;
        }

        return true;
    }

    const handleAlbumClick = async (storieId: number) => {
        try {
            saveToSessionStorage('storie', storieId);
            
            const canRead = await check();
            if (canRead) {
                navigate("/Read");
            }
        } catch (error) {
            const alert = AlertSettings.fromError(error);
            setAlertSettings(alert);
            setOpenAlert(true);
        }
    };

    const getStoriesAsync = async () => {
        try {
            const response = await StorieService.storiesNoText();
            setFeaturedPost(response.data);
        } catch (error) {
            const alert = AlertSettings.fromError(error);

            setAlertSettings(alert);
            setOpenAlert(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        getStoriesAsync();
    }, []);

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert}
            />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sections} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Box sx={{ bgcolor: 'background.paper', pt: 2, pb: 2 }}>
                            <Container sx={{ py: 2 }} maxWidth="md">
                                <Grid container spacing={4}>
                                    {featuredPosts.map((post, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <ActionAreaCard
                                                imageLink={`${BaseUrl}${post.cover}`}
                                                title={post.name}
                                                tierTitle={post.tierTitle}
                                                lastUpdateDate={formatDateToMMDDYYYY(post.lastUpdate)}
                                                viewCount={post.views}
                                                onClick={() => handleAlbumClick(post.storieId)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </Box>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default StorieNoTextCards;
