import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularIndeterminate from '../../components/progress';
import { AlertNotification } from '../../components/alert';
import { AlertSettings, AlertSettingsProps } from '../../utils/interfaces/alert';
import { saveToSessionStorage } from '../../utils/session';
import TokenService from '../../services/token'; // Importe seu serviço aqui


const RedirectStory: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [redirectAfterAlert, setRedirectAfterAlert] = useState(false); // Nova flag

    const check = async () => {
        try {
            const response = await TokenService.get();
            const invalid = Boolean(response.data);

            if (invalid) {
                navigate("/Subscriber");
                return false;
            }
        } catch (error) {
            const alert = AlertSettings.fromError(error);
            setAlertSettings(alert);
            setOpenAlert(true);
            return false;
        }

        return true;
    }

    useEffect(() => {
        const fetchData = async () => {
            
            const searchParams = new URLSearchParams(location.search);
            const storieId = searchParams.get('story');
           
            if (storieId) {
                saveToSessionStorage('storie', storieId);

                const canRead = await check();
                if (canRead) {
                    navigate("/Read");
                }

            } else {
                navigate("/Storie");
            }
        };

        fetchData();
    }, [location.search]);

    // Fecha o alerta e faz o redirecionamento somente se estiver habilitado
    const handleAlertClose = () => {
        setOpenAlert(false);
        if (redirectAfterAlert) {
            navigate("/Storie");
        }
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={handleAlertClose} />
        </>
    );
};

export default RedirectStory;
