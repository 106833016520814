import instance from '../..';

const post = (payload: any) => {
    return instance.post(`/Configuration`, payload);
}

const get = () => {
    return instance.get(`/Configuration`);
}

const ImageConfigurationService = {
    get,
    post
};

export default ImageConfigurationService;