import instance from '..';

const scoped = () => {
    return instance.get(`/subscriber/authorization`);
}

const get = async (hash: string) => {
    return await instance.get(`/subscriber?code=${hash}`);
};

const post = (token: any) => {
    return instance.post(`/subscriber`, token);
}

const SubscriberService = {
    scoped,
    get,
    post,
};

export default SubscriberService;
