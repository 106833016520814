import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container, Grid, TextField, Paper, Button, Box, Typography, InputLabel, Select, MenuItem } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';
import Header from '../../../components/header';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import sectionsAdmin from '../../../utils/menu/admin';
import { AlertSettings, AlertSettingsProps } from '../../../utils/interfaces/alert';
import TierService, { Tiers } from '../../../services/admin/tier';
import convertImageToBase64 from '../../../utils/actions/converter';
import StoryService from '../../../services/admin/storie';
import defaultTheme from '../../../utils/style';

interface StorieHead {
    storieName: string;
    cover: string;
    tierId: number;
    days: number;
    visible: boolean;
}

const StorieManager: React.FC = () => {
    const [storie, setStorie] = useState<StorieHead>({
        storieName: '',
        cover: '',
        tierId: 0,
        days: 0,
        visible: false,
    });
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tiers, setTiers] = useState<Tiers[]>([]);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success',
    });

    useEffect(() => {
        const fetchTiers = async () => {
            setLoading(true);
            try {
                const response = await TierService.get();
                const activeTiers = response.data.filter((tier: Tiers) => !tier.removed);
                setTiers(activeTiers);
            } catch (error) {
                setAlertSettings(AlertSettings.fromError(error));
                setOpenAlert(true);
            } finally {
                setLoading(false);
            }
        };

        fetchTiers();
    }, []);


    const onDrop = async (acceptedFiles: Array<File>) => {
        try {
            const img = await convertImageToBase64(acceptedFiles[0]);
            setStorie((prevStorie) => ({
                ...prevStorie,
                cover: `data:image/png;base64,${img}`,
            }));
            setAcceptedFiles(acceptedFiles);
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
            setOpenAlert(true);
        }
    };

    const handleDeleteImage = () => {
        setStorie((prevStorie) => ({
            ...prevStorie,
            cover: '',
        }));
        setAcceptedFiles([]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'image/*': [] },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target;

        if (name) {
            if (name === 'visible') {
                setStorie((prevStorie) => ({
                    ...prevStorie,
                    [name]: value === 'true',
                }));
            } else {
                setStorie((prevStorie) => ({
                    ...prevStorie,
                    [name]: value,
                }));
            }
        }
    };

    const handleSend = async () => {
        setLoading(true);
        try {
            console.log(storie);
            await StoryService.post_head(storie);
            setAlertSettings({ message: 'Story Created', type: 'success' });
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
        } finally {
            setOpenAlert(true);
            setLoading(false);
        }
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert}
            />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Storie Manager" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                            <Typography variant="h6" gutterBottom>
                                Add New Cover
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                gap={2}
                                sx={{ mt: 2 }}
                            >
                                <Button variant="contained" {...getRootProps()} sx={{ mb: 2 }}>
                                    Select Cover
                                </Button>
                                <input {...getInputProps()} />
                                {storie.cover ? (
                                    <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
                                        <img
                                            src={storie.cover}
                                            alt="Cover Preview"
                                            style={{
                                                maxWidth: '300px',
                                                maxHeight: '300px',
                                                borderRadius: '8px',
                                                marginBottom: '10px',
                                            }}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={handleDeleteImage}
                                        >
                                            Remove Image
                                        </Button>
                                    </Box>
                                ) : (
                                    <Typography variant="body2" color="text.secondary">
                                        No cover selected
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                <TextField
                                    fullWidth
                                    label="Storie Name"
                                    variant="outlined"
                                    name="storieName"
                                    value={storie.storieName}
                                    onChange={handleChange}
                                    sx={{ mb: 2 }}
                                />
                                <InputLabel htmlFor="tierId">Tier</InputLabel>
                                <Select
                                    id="tierId"
                                    name="tierId"
                                    fullWidth
                                    value={storie.tierId}
                                    onChange={(e) => handleChange(e as any)}
                                    sx={{ mb: 2 }}
                                    label="Tier"
                                >
                                    <MenuItem value={0}>Select a Tier</MenuItem>
                                    {tiers.map((tier) => (
                                        <MenuItem key={tier.id} value={tier.id}>
                                            {`${tier.title} - $${tier.cost}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    fullWidth
                                    label="Days"
                                    variant="outlined"
                                    name="days"
                                    value={storie.days}
                                    onChange={handleChange}
                                    sx={{ mb: 2 }}
                                />
                                <InputLabel htmlFor="Visible">Visible</InputLabel>
                                <Select
                                    id="Visible"
                                    name="visible"
                                    fullWidth
                                    value={storie.visible ? 'true' : 'false'}
                                    onChange={(e) => handleChange(e as any)}
                                    sx={{ mb: 2 }}
                                    label="Tier"
                                >
                                    <MenuItem value="true">Visible</MenuItem>
                                    <MenuItem value="false">Not Visible</MenuItem>
                                    ))
                                </Select>

                                <Button variant="contained" color="primary" onClick={handleSend}>
                                    Save Storie
                                </Button>
                            </Box>
                        </Paper>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default StorieManager;
