import './assets/css/App.css'
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StorieCards from './pages/Stories';
import TokenAuthenticate from './pages/Token'
import PagesCard from './pages/Album';
import Subscriber from './pages/Subscriber';
import UserRegister from './pages/Admin/User';
import UserBan from './pages/Admin/User/Ban';
import StorieManager from './pages/Admin/Storie';
import UploadPages from './pages/Admin/Storie/Pages';
import Admin from './pages/Admin';
import StorieNoTextCards from './pages/Stories/NoText';
import UploadNoTextPages from './pages/Admin/Storie/Pages/NoText';
import ImageConfigurationManager from './pages/Admin/ImageConfiguration';
import RedirectStory from './pages/Redirect';

const defaultTheme = createTheme();

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path='/*' element={<StorieCards />}></Route>
          <Route path='/NoText*' element={<StorieNoTextCards />}></Route>
          <Route path='/Read' element={<PagesCard />}></Route>
          <Route path='/Token' element={<TokenAuthenticate />}></Route>
          <Route path='/Subscriber' element={<Subscriber />}></Route>
          <Route path='/Admin' element={<Admin />}></Route>
          <Route path='/User' element={<UserRegister />}></Route>
          <Route path='/UserBan' element={<UserBan />}></Route>
          <Route path='/Create' element={<StorieManager />}></Route>
          <Route path='/Upload' element={<UploadPages />}></Route>
          <Route path='/NoTextUpload' element={<UploadNoTextPages />}></Route>
          <Route path='/Config' element={<ImageConfigurationManager />}></Route>
          <Route path='/Redirect' element={<RedirectStory />}></Route>
        </Routes>
      </Router>
    </>
  )
}

export default App;
