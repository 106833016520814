import instance from '..';

const stories = () => {
    return instance.get(`/storie/stories`);
}

const storiesNoText = () => {
    return instance.get(`/storie/stories-notext`);
}

const read = async (storieId: number) => {
    return await instance.get(`/storie/?storieId=${storieId}`);
};

const download = async (payload: any) => {
    return await instance.post(`/storie/download`, payload);
}

const StorieService = {
    storiesNoText,
    stories,
    read,
    download,
};

export default StorieService;